.auth-parent{
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: linear-gradient(to right, rgb(0, 0, 0) 0%, #a9a9a9 100%);
  position: absolute;
  width: 100%;
}
.auth-parent h1{
  font-size: 22px;
  font-weight: bold;
}
.auth-parent form{
  width: 400px;
  box-shadow: 0 0 3px gray;
  padding: 20px;
  background-color: white ;
  z-index: 1;
  padding: 60px 30px;
  opacity: 0.85;
}


.brand{
  font-size: 250px ;
  position: absolute;
  top: 30%;
  width: 100%;
  left: 0;
  text-align: center;
  transform: rotate(-10deg);
  color: rgba(0, 0, 0, 0.747);
  transform: translate(-50% , -50%);
}

@media only screen and (max-width:1000px){
  .brand{
      display: none;
  }
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
}

button {
  background-color: rgba(0, 0, 0, 0.85);
  box-shadow: none;
  outline: none;
  border: none;
  color: white;
}

input,
textarea {
  box-shadow: none;
  outline: none;
  border: 1px solid gray;
}

input:focus,
textarea:focus {
  outline: none;
  box-shadow: none;
}

.ant-spin {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
}
.ant-btn-dashed {
  background: white;
  color: black;
  border: 1px dashed gray;
  width: 200px;
}

.divider {
  width: 100%;
  height: 2px;
  background-color: gray;
}
.back-btn {
  background-color: white;
  color: black;
  border: 1px solid black;
}

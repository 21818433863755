.layout{
    height: 100vh;
    padding: 20px 50px;
}

@media only screen and (max-width:768px){
    .layout{
        height: 100vh;
        padding: 5px 5px;
        
    }
  }

.header{
    height: 60px;
    background-color: rgba(0, 0, 0, 0.85);
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    
}
.header h1{
    color: rgba(255, 255, 255, 0.5);
    font-size: 22px;
}
.header button{
    border: 1px solid white ;
    color: rgba(0, 0, 0, 0.589) ;
    
}
.content{
    height: calc(100% - 70px);
    border: 1px solid gray;
    margin-top: 10px;
    border-radius: 5px;
    box-shadow: 0 0 30px rgba(128, 128, 128, 0.486);
    padding: 20px;
}
